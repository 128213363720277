
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function vehicleLabelContainerRT () {
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__page-title' }, _createElement('h2', { 'className': 'container-header' }, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cmTemplate_locked',
        'key': '93'
    }, _createElement('span', { 'className': 'cm_vehicle-widget_title' }, this.vehicleString, ' '), _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle)) : null, this.template !== 'locked' ? [_createElement('span', { 'key': '3631' }, this.fitmentSearchTitle)] : null));
}
        export const componentNames = []