//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-572:_6064,_872,_1528,_8460,_2920,_5376,_2980,_7292;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-572')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-572', "_6064,_872,_1528,_8460,_2920,_5376,_2980,_7292");
        }
      }catch (ex) {
        console.error(ex);
      }