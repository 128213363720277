//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-572:_4940,_8200,_7108,_4344,_8320,_848,_4104,_7972;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-572')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-572', "_4940,_8200,_7108,_4344,_8320,_848,_4104,_7972");
        }
      }catch (ex) {
        console.error(ex);
      }