
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function MainContentRT () {
    return _createElement('div', { 'id': 'itemsBlock' }, [this.SearchVehicleLabel(function () {
            return window.document.body.classList.contains('cm_search') ? _createElement('div', {
                'className': 'cm_vehicle-widget cm_vehicle-widget__page-title cm_SearchVehicleLabel',
                'key': '0'
            }, _createElement('h2', { 'className': 'container-header' }, this.template === 'locked' ? _createElement('div', {
                'className': 'cm_vehicle-widget_label cmTemplate_locked',
                'key': '186'
            }, _createElement('span', { 'className': 'cm_vehicle-widget_title' }, this.vehicleString, ' '), _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle)) : null, this.template !== 'locked' ? [_createElement('span', { 'key': '4561' }, this.fitmentSearchTitle)] : null)) : null;
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '4950'
                }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '7690'
                }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1136'
            }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1554'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '1816'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2108'
            }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                '\n    This ',
                this.pageType,
                ' doesn\'t have any\n    ',
                !!this.vehicleString ? [
                    ' parts that fit ',
                    _createElement('span', { 'key': '23371' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString ? [' products.'] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '25411' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString ? [
                '\n    Press ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '26721'
                }, 'here'),
                ' to clear\n    ',
                !this.vehicleString ? [' selection. '] : null,
                this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3065'
            }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3280'
            }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '3509'
            }, 'We failed to process your request', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '36550' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '37420' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '38250' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '36204' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.customMessage(function () {
            return _createElement('div', { 'className': 'cm_message cm_customMessage' }, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.FacetBarWheels(function () {
            function repeatSelects1(facet_bar_label, selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry3(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry4(entry, index) {
                            return _createElement('div', {
                                'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3683'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3885'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': 'cmTemplate_selectFacet' }, _createElement('div', { 'className': 'cm_vehicle-widget_select' }, [_createElement('div', {
                                'className': this.extraClassName,
                                'key': '980'
                            }, !this.isSearchable ? _createElement('div', {
                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                'key': '149'
                            }, _createElement('select', {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                'disabled': this.disabled,
                                'aria-label': this.title
                            }, this.useNativeDropdown ? [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          ', '', this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '8901'
                                    }, 'Popular Makes'),
                                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '8904'
                                    }, 'All Makes'),
                                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                ] : null,
                                this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                            ] : null, !this.useNativeDropdown ? _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                        return _createElement('div', { 'className': 'cm_filterInput' });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                    'aria-label': this.title,
                                    'key': '2979'
                                },
                                !this.hideNullOption ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'onClick': () => this.onChange('')
                                }, '\n      ', '', this.title, '\n    ') : null,
                                this.loading ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                            ]) : null)]));
                    }, { count: undefined })];
            }
            function scopeFacet_bar_label2() {
                var facet_bar_label = 'Shop Wheels';
                return _createElement('div', { 'className': 'cm_FacetBarWheels' }, _createElement('div', { 'className': 'cm_FacetBarWheelsTires cm_facets' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__facet-bar' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n        ', facet_bar_label, '\n      '), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cm_vehicle-widget_select-container cmRepeater_facets cmRepeater_selects' },
                    _map(this.selects, repeatSelects1.bind(this, facet_bar_label))
                ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                    'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button button--primary cm_button-primary',
                    'onClick': this.discardFields,
                    'disabled': !this.hasSelection,
                    'role': 'button',
                    'tabIndex': this.hasSelection ? '0' : '-1'
                }, '\n            Clear\n          ')))))));
            }
            return scopeFacet_bar_label2.apply(this, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.FacetBarTires(function () {
            function repeatSelects1(facet_bar_label, selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry3(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry4(entry, index) {
                            return _createElement('div', {
                                'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3683'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3885'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': 'cmTemplate_selectFacet' }, _createElement('div', { 'className': 'cm_vehicle-widget_select' }, [_createElement('div', {
                                'className': this.extraClassName,
                                'key': '980'
                            }, !this.isSearchable ? _createElement('div', {
                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                'key': '149'
                            }, _createElement('select', {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                'disabled': this.disabled,
                                'aria-label': this.title
                            }, this.useNativeDropdown ? [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          ', '', this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '8901'
                                    }, 'Popular Makes'),
                                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '8904'
                                    }, 'All Makes'),
                                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                ] : null,
                                this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                            ] : null, !this.useNativeDropdown ? _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                        return _createElement('div', { 'className': 'cm_filterInput' });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                    'aria-label': this.title,
                                    'key': '2979'
                                },
                                !this.hideNullOption ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'onClick': () => this.onChange('')
                                }, '\n      ', '', this.title, '\n    ') : null,
                                this.loading ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                            ]) : null)]));
                    }, { count: undefined })];
            }
            function scopeFacet_bar_label2() {
                var facet_bar_label = 'Shop Tires';
                return _createElement('div', { 'className': 'cm_FacetBarTires' }, _createElement('div', { 'className': 'cm_FacetBarWheelsTires cm_facets' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__facet-bar' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n        ', facet_bar_label, '\n      '), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cm_vehicle-widget_select-container cmRepeater_facets cmRepeater_selects' },
                    _map(this.selects, repeatSelects1.bind(this, facet_bar_label))
                ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                    'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button button--primary cm_button-primary',
                    'onClick': this.discardFields,
                    'disabled': !this.hasSelection,
                    'role': 'button',
                    'tabIndex': this.hasSelection ? '0' : '-1'
                }, '\n            Clear\n          ')))))));
            }
            return scopeFacet_bar_label2.apply(this, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'button button--primary cm_button-primary cm_dialogButton' }, '\n  Filters \n    ', this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '-5 0 394 394.00003',
                                    'key': '860'
                                }, _createElement('path', { 'd': 'm367.82 0h-351.26c-6.1992-0.011719-11.879 3.4492-14.711 8.9609-2.8711 5.5859-2.3672 12.312 1.3008 17.414l128.69 181.29c0.042968 0.0625 0.089843 0.12109 0.13281 0.18359 4.6758 6.3125 7.207 13.961 7.2188 21.816v147.8c-0.027344 4.375 1.6914 8.582 4.7734 11.688 3.0859 3.1016 7.2812 4.8516 11.656 4.8516 2.2227-0.003906 4.4258-0.44531 6.4805-1.3008l72.312-27.57c6.4766-1.9805 10.777-8.0938 10.777-15.453v-120.02c0.011719-7.8555 2.543-15.504 7.2148-21.816 0.042968-0.0625 0.089844-0.12109 0.13281-0.18359l128.69-181.29c3.668-5.0977 4.1719-11.82 1.3008-17.406-2.8281-5.5156-8.5117-8.9766-14.707-8.9648z' }))] : null, !this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '0 0 247.46 247.46',
                                    'key': '8270'
                                }, _createElement('path', { 'd': 'm246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65l-80.958 98.191z' }))] : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', {
                'className': 'cm_total-hits',
                'role': 'status'
            }, _createElement('span', {}, this.totalHits, ' results')), [_createElement('div', {
                    'className': 'grid-list-view toggle cm_view-toggle',
                    'key': '22780'
                }, _createElement('a', {
                    'className': 'toggle-link grid default' + (this.view === 'productGrid' ? ' active' : ''),
                    'onClick': this.setView('productGrid'),
                    'title': 'Toggle Grid View'
                }, _createElement('svg', { 'className': 'toggle grid' }, _createElement('use', { 'xlinkHref': '#icon-grid' }))), _createElement('a', {
                    'className': 'toggle-link list secondary' + (this.view === 'productList' ? ' active' : ''),
                    'onClick': this.setView('productList'),
                    'title': 'Toggle List View'
                }, _createElement('svg', { 'className': 'toggle list' }, _createElement('use', { 'xlinkHref': '#icon-list' }))))], _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, 'Sort by: '), '\n  ', this.sortSelect, '\n'));
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        return _createElement('li', { 'className': 'product' }, _createElement('article', {
                            'id': this.id,
                            'className': 'card compare-enabled price-visibility sale-label-active before-sale-price-visible withoutTax'
                        }, _createElement('figure', { 'className': 'card-figure sale-flag-active' }, this.on_sale ? _createElement('div', {
                            'className': 'sale-flag-side',
                            'key': '205'
                        }, _createElement('span', { 'className': 'sale-text' }, 'SALE')) : null, _createElement('a', {
                            'className': 'image-link',
                            'href': this.custom_url
                        }, _createElement('img', {
                            'className': 'card-image primary lazyautosizes lazyloaded',
                            'src': this.imageOrDefault(this.images_image_thumbnail1),
                            'alt': this.removeHTML(this.name),
                            'onError': this.onImageError
                        })), _createElement('div', {
                            'className': 'image-link mobile',
                            'onClick': window.Convermax.handleMobileProductImageClick
                        }, _createElement('img', {
                            'className': 'card-image primary lazyload',
                            'src': this.imageOrDefault(this.images_image_thumbnail1),
                            'alt': this.removeHTML(this.name),
                            'onError': this.onImageError
                        })), _createElement('figcaption', { 'className': 'card-figcaption' }, _createElement('div', { 'className': 'card-figcaption-body' }, _createElement('a', {
                            'className': 'button button--small card-figcaption-button quickview',
                            'data-product-id': this.id
                        }, '\n            Quick view\n          '), _createElement('a', {
                            'className': 'mobile-image-link',
                            'href': this.custom_url
                        }, _createElement('span', { 'className': 'mobile-link-text' }, 'Details'))))), _createElement('div', { 'className': 'card-body purchase-ability ratings-on withoutTax' }, this.brand_name ? _createElement('div', {
                            'className': 'card-text brand',
                            'key': '1339'
                        }, '\n        Brand: ', _createElement('a', mergeProps({
                            'className': 'brand-link',
                            'href': this.brand_url
                        }, { dangerouslySetInnerHTML: { __html: this.brand_name } }))) : null, this.sku ? _createElement('div', {
                            'className': 'card-text sku',
                            'key': '1540'
                        }, '\n        SKU:\n        ', _createElement('span', mergeProps({ 'className': 'sku-value' }, { dangerouslySetInnerHTML: { __html: this.sku } }))) : null, _createElement('h4', { 'className': 'card-title' }, _createElement('a', Object.assign({}, { 'href': this.custom_url }, { dangerouslySetInnerHTML: { __html: this.name } }))), _createElement('div', { 'className': 'card-text' }, _createElement('div', { 'className': 'price-visibility' }, _createElement('div', { 'className': 'msrp-sale-regular-price-section withoutTax' }, this.on_sale ? _createElement('div', {
                            'className': 'price-section price-section--withoutTax rrp-price--withoutTax',
                            'key': '1990'
                        }, _createElement('span', { 'className': 'price-label' }, 'Was:'), _createElement('span', {
                            'data-product-rrp-price-without-tax': true,
                            'className': 'price price--rrp'
                        }, '\n                ', this.formatPrice(this.regular_price), '\n              ')) : null), _createElement('div', { 'className': 'price-section price-section--withoutTax current-price' + (this.on_sale ? ' sale-price' : ' regular-price') }, _createElement('span', { 'className': 'price-label price-now-label' }, this.on_sale ? 'Now:' : ''), _createElement('span', {
                            'data-product-price-without-tax': true,
                            'className': 'price price--withoutTax'
                        }, '\n              ', this.formatPrice(this.price), '\n            ')), this.set_qty ? _createElement('div', {
                            'className': 'price-item--set',
                            'key': '2770'
                        }, '\n            Set of ', this.set_qty, ': ', this.formatPrice(this.price * this.set_qty), '\n          ') : null), _createElement('div', { 'className': 'card-text add-to-cart-button' }, !this.out_of_stock ? _createElement('a', {
                            'href': '/cart.php?action=add&product_id=' + this.id,
                            'className': 'button button--small card-figcaption-button',
                            'key': '2995'
                        }, '\n            Add to Cart\n          ') : null, this.out_of_stock ? _createElement('div', {
                            'className': 'button button--small card-figcaption-button',
                            'disabled': true,
                            'key': '3180'
                        }, '\n            Out of stock\n          ') : null), _createElement('label', {
                            'className': 'button button--small card-figcaption-button compare',
                            'htmlFor': 'compare-' + this.id
                        }, _createElement('input', {
                            'type': 'checkbox',
                            'name': 'products[]',
                            'value': this.id,
                            'id': 'compare-' + this.id,
                            'data-compare-id': this.id,
                            'onClick': window.Convermax.handleProductCompareClick
                        }), _createElement('span', { 'className': 'compare-text' }, 'Compare'))))));
                    }, { count: undefined })];
            }
            function repeatItems2(items, itemsIndex) {
                return [items(function () {
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        return _createElement('li', { 'className': 'product' }, _createElement('article', { 'className': 'listItem compare-enabled price-visibility sale-label-active before-sale-price-visible withoutTax' }, _createElement('figure', { 'className': 'listItem-figure sale-flag-active' }, this.on_sale ? _createElement('div', {
                            'className': 'sale-flag-side',
                            'key': '198'
                        }, _createElement('span', { 'className': 'sale-text' }, 'SALE')) : null, _createElement('a', {
                            'className': 'image-link',
                            'href': this.custom_url
                        }, _createElement('img', {
                            'className': 'listItem-image lazyautosizes lazyloaded',
                            'src': this.imageOrDefault(this.images_image_thumbnail1),
                            'alt': this.removeHTML(this.name),
                            'onError': this.onImageError
                        }), this.images_image_thumbnail2 ? _createElement('img', {
                            'className': 'listItem-image alt',
                            'src': this.imageOrDefault(this.images_image_thumbnail2),
                            'alt': this.removeHTML(this.name),
                            'onError': this.onImageError,
                            'key': '553'
                        }) : null), _createElement('div', { 'className': 'listItem-figureBody' }, _createElement('a', {
                            'className': 'button button--small listItem-button quickview',
                            'data-product-id': this.id
                        }, 'Quick view'))), _createElement('div', { 'className': 'listItem-body' }, _createElement('div', { 'className': 'listItem-content' }, _createElement('div', { 'className': 'listItem-details' }, this.brand_name ? _createElement('div', {
                            'className': 'card-text brand',
                            'key': '1052'
                        }, '\n            Brand: ', _createElement('a', mergeProps({
                            'className': 'brand-link',
                            'href': this.brand_url
                        }, { dangerouslySetInnerHTML: { __html: this.brand_name } }))) : null, this.sku ? _createElement('div', {
                            'className': 'card-text sku',
                            'key': '1265'
                        }, '\n            SKU:\n            ', _createElement('span', mergeProps({ 'className': 'sku-value' }, { dangerouslySetInnerHTML: { __html: this.sku } }))) : null, _createElement('h4', { 'className': 'listItem-title' }, _createElement('a', Object.assign({}, { 'href': this.custom_url }, { dangerouslySetInnerHTML: { __html: this.name } }))), _createElement('p', {}, this.description)), _createElement('div', { 'className': 'listItem-actions' }, _createElement('div', { 'className': 'listItem-price' }, this.on_sale ? _createElement('div', {
                            'className': 'msrp-sale-regular-price-section withoutTax',
                            'key': '1736'
                        }, _createElement('div', { 'className': 'price-section price-section--withoutTax rrp-price--withoutTax' }, _createElement('span', { 'className': 'price-label' }, 'MSRP:'), _createElement('span', {
                            'data-product-rrp-price-without-tax': true,
                            'className': 'price price--rrp'
                        }, '\n                  ', this.formatPrice(this.regular_price), '\n                '))) : null, _createElement('div', { 'className': 'price-section price-section--withoutTax current-price' + (this.on_sale ? ' sale-price' : ' regular-price') }, _createElement('span', { 'className': 'price-label price-now-label' }, this.on_sale ? 'Now:' : ''), _createElement('span', {
                            'data-product-price-without-tax': true,
                            'className': 'price price--withoutTax'
                        }, '\n                ', this.formatPrice(this.price), '\n              ')), this.set_qty ? _createElement('div', {
                            'className': 'price-item--set',
                            'key': '2614'
                        }, '\n              Set of ', this.set_qty, ': ', this.formatPrice(this.price * this.set_qty), '\n            ') : null, _createElement('div', { 'className': 'card-text add-to-cart-button' }, !this.out_of_stock ? _createElement('a', {
                            'href': '/cart.php?action=add&product_id=' + this.id,
                            'className': 'button button--small card-figcaption-button',
                            'key': '2836'
                        }, '\n                Add to Cart\n              ') : null, this.out_of_stock ? _createElement('div', {
                            'className': 'button button--small card-figcaption-button',
                            'disabled': true,
                            'key': '3033'
                        }, '\n                Out of stock\n              ') : null), _createElement('label', {
                            'className': 'button button--small card-figcaption-button compare',
                            'htmlFor': 'compare-' + this.id
                        }, _createElement('input', {
                            'type': 'checkbox',
                            'name': 'products[]',
                            'value': this.id,
                            'id': 'compare-' + this.id,
                            'data-compare-id': this.id,
                            'onClick': window.Convermax.handleProductCompareClick
                        }), _createElement('span', { 'className': 'compare-text' }, 'Compare')))), _createElement('div', { 'className': 'clear' })))));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_SearchResult' }, this.view === 'productGrid' ? _createElement.apply(this, [
                'ul',
                {
                    'className': 'products ' + this.view + ' visible cmRepeater_items',
                    'key': '34'
                },
                _map(this.items, repeatItems1.bind(this))
            ]) : null, this.view === 'productList' ? _createElement.apply(this, [
                'ul',
                {
                    'className': 'products ' + this.view + ' visible cmRepeater_items',
                    'key': '6819'
                },
                _map(this.items, repeatItems2.bind(this))
            ]) : null, [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '4950'
                        }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '7690'
                        }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1136'
                    }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1554'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '1816'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2108'
                    }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                        '\n    This ',
                        this.pageType,
                        ' doesn\'t have any\n    ',
                        !!this.vehicleString ? [
                            ' parts that fit ',
                            _createElement('span', { 'key': '23371' }, '\'', this.vehicleString, '\'.')
                        ] : null,
                        !this.vehicleString ? [' products.'] : null
                    ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '25411' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString ? [
                        '\n    Press ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '26721'
                        }, 'here'),
                        ' to clear\n    ',
                        !this.vehicleString ? [' selection. '] : null,
                        this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
                    ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '3065'
                    }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '3280'
                    }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '3509'
                    }, 'We failed to process your request', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '36550' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '37420' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '38250' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '36204' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], [this.loadMore(function () {
                    return _createElement('div', { 'className': 'cm_loadMore' }, _createElement('div', { 'className': 'cm_load-more' }, this.isNotAllItemsReceived ? [
                        this.isActive ? [this.loading] : null,
                        !this.isActive ? [[this.loadMoreButton(function () {
                                    return _createElement('div', {
                                        'className': 'btn btn-default btn-inverse button button--primary cm_button-primary cm_loadMoreButton',
                                        'onClick': this.activate
                                    }, '\n        ', window.Convermax.config?.loadMoreText || `Next ${ this.nextPageSize }`, '\n      ');
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null
                    ] : null));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })], _createElement('a', {
        'className': 'cm_compare-btn disabled',
        'href': '/compare/'
    }, 'Compare Selected'));
}
        export const componentNames = ["cm:SearchVehicleLabel","cm:message","cm:customMessage","cm:filterInput","cm:FacetBarWheels","cm:filterInput","cm:FacetBarTires","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:message","cm:loadMoreButton","cm:loadMore","cm:SearchResult"]